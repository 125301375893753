@media (max-width: 450px) {
  .imgList {
    display: none;
  }
  .sectionTwoWrapper {   
    height: 435px;
  }
  .eachSegmentWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

  }
  .list {
    width: 70%;
  }

}

@media (min-width: 451px) {
 
  .sectionTwoWrapper{
    height: 488px;
  }
  .eachSegmentWrapper{
    display: flex;
    align-items: center;
    justify-content: space-evenly;

  }



}








/* SECCION UNO */

.sectionOneWrapper {
  font-size: 1.0rem;
  font-family: var(--font-lato);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 30px 0px;

}

.eachSegmentWrapper {
  width: 80%;
  margin: 15px 0px;
  flex-direction: column;
  display: flex;
}

.titleStyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.5rem;
  letter-spacing: -0.02em;
  font-weight: 800;
  color: var(--esmeralda);
  line-height: 150%;
  width: 70%;
  justify-content: flex-start;
}

.paragraphStyle {
  color: var(--color-black);
  text-align: justify;
  width: 70%;
}

/*SECCION DOS */


.titleList {
  color: var(--esmeralda);
  font-family: var(--font-lato);
  font-weight: bold;
  font-size: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 91px;
}

.listAndImg {
  width: 100%;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
  text-align: justify;
  display: flex;
  flex-direction: row;
  justify-content: center;

}
.list{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: var(--font-lato);


}
.imgList {
  width: 16.93rem;
  height: 16.21rem;
  object-fit: cover;
}





.whatToExpect {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 231.43rem;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--esmeralda);
  font-family: var(--font-lato);
}


