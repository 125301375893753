.footer {
  height: 10rem;
  text-align: justify;
  font-size: 0.9rem;
  color: #ffff;
  font-family: var(--font-lato);
  justify-content: space-evenly;
  display: flex;
}

.instaDiv,
.whatsappDiv,
.emailDiv {
  display:flex;
  align-items: center;
}

.descrFooter{
  font-size: 2rem;
}
.descrFooter2{
  font-size: 1rem;
}


.wrapLeft{
  display: flex;
  margin: 3px;
  flex-direction: column;
  justify-content: center;
}

.wrapRight{
  margin: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


@media (max-width: 450px) {
  .footer {
    display: none; 
  }
}


