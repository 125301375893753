.card {
  width: 250px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  margin: 20px;
  font-family: var(--font-lato);
  color: #0c6267;


}
.card img {
  width: 100%;
  height: auto;
}
.cardContent {
  padding: 20px;
}
.cardContent h2 {
  margin: 0;
  font-size: 20px;
}
.cardContent p {
  margin: 10px 0;
  font-size: 16px;
}

.cardLink {
  text-decoration: none;
  color: inherit; /* Use the same color as the parent element */
}

.cardLink:hover {
  text-decoration: underline; /* Underline the link on hover */
}
