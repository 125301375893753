/* base styles */

 .hamMenuContainer {
  display: none;
  background-color: #0C6267;
} 

.menuToggle {
  cursor: pointer;
  display: inline-block;
  z-index: 2;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #FFF;
  margin: 6px 0;
  transition: 0.4s;
}

.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.open .bar2 {
  opacity: 0;
}

.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  z-index: 1;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  font-family: Lato;

}

.menu.open {
  transform: translateX(0);
}

.menu ul {
  list-style-type: none;
  padding: 0;
  margin: 100px 0 0 0;
  text-align: center;
}

.menu ul li {
  padding: 20px 0;
  color: #0c6267;
  font-weight: bold;
}

.menu ul li:hover {
  background-color: #9bc0c2;
}


@media (max-width: 450px) {
  .hamMenuContainer {
    display: flex;
  }
}


.logoNavComponent {
  margin-left: 196px ;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 1.7rem;
  color: var(--color-white);
  font-family: var(--font-lato);
}

.textLogoNav,
.imgLogoNav {
  position: relative;
  flex-shrink: 0;
}
.imgLogoNav {
  width: 3.2rem;
  height: 2.20rem;
  overflow: hidden;
}
.textLogoNav {

  align-self: center;
}
