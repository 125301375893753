.contentHomeWraper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: var(--font-lato);
  margin-bottom: 30px;
}

.sectionOneHomeWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 154px;
}
.titleOneHome {
  color: #0c6267;
  font-size: 1.5rem;
}

.descriptionOneHome {
  font-size: 1.2rem;
  color: var(--color-black);
}

.sectionTwoHomeWrapper {
  text-align: justify;

  line-height: 240.5%;
  white-space: pre-wrap;

  width: 60%;
}
.titleTwoHome {
  position: relative;
  line-height: 113.5%;
  color: #0c6267;
  font-size: 1.5rem;
}
.descriptionTwoHome {
  font-size: 1.2rem;
  color: var(--color-black);
  width: 100%;
}
.sectionThreeHomeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1;
  font-family: var(--font-lato);
}
.titleThreeHome {
  position: relative;
  display: flex;
  width: 100%;
  color: #0c6267;
  font-size: 6.29rem;
  justify-content: center;
  margin: 16px 0;
}

.titleCard {
  position: relative;
  line-height: 113.5%;
  color: #0c6267;
  font-size: 1.5rem;
  font-family: var(--font-lato);
  display: flex;
  justify-content: center;
}

.sectionTwo {
  margin: 10% 0%;
}

@media (max-width: 450px) {
  .sectionOneHomeWrapper {
    width: 70%;
  }
  .sectionTwoHomeWrapper {
    width: 70%;
  }

  .cardd {
    display: flex;
   
    align-items: center;
    flex-direction: column;
  }
}

@media (min-width: 451px) {
  .sectionOneHomeWrapper {
    width: 60%;
  }
  .sectionTwoHomeWrapper {
    width: 60%;
  }

  .cardd {
    display: flex;
    justify-content: center;
  }
}
