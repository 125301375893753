@media (max-width: 450px) {
  .navComponent {
    display: none;
  }
}
.navComponent {
  position: absolute;
  top: 51px;
  right: 142px;
  justify-content: flex-start;
  gap: var(--gap-20xl);
  text-align: left;
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-lato);

}

.navComponent>.navContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 552px;
}

.navComponent>.navContainer>.navItem {
  display: flex;
  font-weight: 800;
  flex: 1;
  justify-content: center;
}

.navComponent>.navContainer>.navItemRegister {
 background-color: #157075;
 padding: var(--padding-3xs);
 flex: 1;
 text-align: center;
 font-weight: 800;
}

.navComponent>.registerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--color-silver);
  flex-shrink: 0;
  justify-content: center;
}
a { color: inherit;
  text-decoration: none !important;
} 

.openState {
  display: block;
}
.closeState {
  display: none;
}

.aboutUsList {
  position: absolute;
  top: 25px;
  background-color: rgba(21, 112, 117, 0.5);
  border-radius: var(--border-radius);
  padding: 4px;
  width: 148px;
}

.aboutUsTrigger:hover {
  cursor: pointer;
}



ul.myList  {
  list-style-type: none;
  margin: 0%;
  padding: 0%;
  text-align: center;
}