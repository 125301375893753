@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Hebrew:wght@400;700;800&display=swap");

/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}

html {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

/*
  Typographic tweaks!
  3. Add accessible line-height
  4. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*
  5. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
  
}
/*
  6. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  7. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  8. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}

:root {
  /* fonts */
  --font-lato: Lato;

  /* font sizes */
 /* 1rem = 16 pt */ 
 /* Paraghrap 16 to 20 px */
 /* Titles 18 to 29px */

/*  title 24px = 1.5rem
 big title 28.8px = 1.8rem */

  --font-size-title: 1.5rem; 
  --font-size-bigtitle: 1.8rem; 
  
  --font-size-11xl: 2.14rem;
  --font-size-21xl: 1.6rem;
  --font-size-xl: 1.43rem;
  --font-size-29xl: 3.43rem;
  --font-size-17xl: 2.57rem;
  --font-size-9xl: 2rem;
  --font-size-45xl: 4.57rem;

  /* Colors */
  --color-white: #fff;
  --esmeralda: #3b7a7d;
  --color-teal-100: #157075;
  --color-darkslategray: #0c6267;
  --color-gainsboro: #d9d9d9;
  --color-black: #000;
  --color-silver: #a7bfc7;
  --color-lightblue: #9bc0c2;
}
