@media (max-width: 450px) {
.eachSegmentWrapper{
  width: 80%;

}
.paragraphRefundWrapper{
  width: 80%;
}
.paragraphRefundInf {
  width: 80%;
}


}


@media (min-width: 451px) {

  .eachSegmentWrapper{
    width: 70%;
  
  }
  .paragraphRefundWrapper{
    width: 80%;
  }
  .paragraphRefundInf {
    width: 70%;
  }
  

}











/* SECTION ONE */

.sectionOneWrapper {
  font-size: 1.0rem;
  font-family: var(--font-lato);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  align-items: center;
}

.eachSegmentWrapper {
  margin: 5px 0px;
  display: flex;
  flex-direction: column;
}

.titleStyle {
  font-size: 1.2rem;
  letter-spacing: -0.02em;
  font-weight: 800;
  color: var(--esmeralda);
  line-height: 150%;
  width: 100%;
}

.paragraphStyle {
  color: var(--color-black);
  text-align: justify;
}

/* SECTION TWO */

.sectionTwoWrapper {
  display: flex;
  margin: 15px 0px;
  flex-direction: column;
}

.titleSectionTwo {
  color: var(--esmeralda);
  font-family: var(--font-lato);
  font-weight: bold;
  font-size: 23px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
}
.paragraphRefundWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  font-family: var(--font-lato);
  font-size: 1.0rem;
  margin-bottom: 15px;

}

/* SECTION THREE */

.sectionThreeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleSectionThree {
  padding: 0 15%;
  font-family: var(--font-lato);
  font-size: 1.2rem;
  text-align: justify;
  margin-bottom: 40px;
}

.imgSectionThree {
  width: 55%;
  height: 100%;
  object-fit: cover;
}


