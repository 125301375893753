.form{

   width: 100%;
}

.wrapperForm{
    display: flex;
   width: 100%;
   height: 1600px;
}

@media (max-width: 450px) {
    .wrapperForm {
     height: 1900px;
    }
  }
  