@media (max-width: 450px) {
  .wrapperImg {
    display: none;
  }
  .twoWrapper {
    flex-direction: column;
  }

  .wrapperOne {
    width: 80%;
  }
  .maps {
    width: 340px;
    height: 234px;
  }
}

@media (min-width: 451px) {
  .wrapperImg {
    display: flex;
  }
  .wrapperOne {
    width: 29%;
  }

  .maps {
    width: 770px;
    height: 450px;
  }
}

.sectionOneWrapper,
.sectionTwoWrapper {
  font-size: 1.1rem;
  font-family: var(--font-lato);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;

}



.wrapperOne {
  margin: 1%;
}
.wrapperImg {
  width: 29%;
  margin: 1%;
}

.twoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

}

.eachSegmentWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.titleStyle {
  font-size: 1.3rem;
  letter-spacing: -0.02em;
  font-weight: 800;
  color: var(--esmeralda);
  line-height: 150%;
  width: 100%;
}

.paragraphStyle {
  color: var(--color-black);
  text-align: justify;
  width: 100%;
}

.imgMtp {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.maps {
  border: 0;
}
