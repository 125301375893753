@media (max-width: 450px) {
  .dutiesGroupWrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .eachSegmentWrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .titleStyle {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .paragraphStyle {
    width: 80%;
  }

  .dutiesWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
  }
}

@media (min-width: 451px) {
  .titleStyle {
    width: 60%;
  }
  .paragraphStyle {
    width: 60%;
  }

  .dutiesWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    flex-direction: column;

  }


  
}

/* SECCION UNO */
.mainTitle {
  position: relative;
  font-size: 1.5rem;
  display: inline-block;
  font-family: var(--font-lato);
  color: var(--esmeralda);
  width: 100%;
  text-align: center;
}

.sectionOneWrapper {
  margin: 40px 0px;
  width: 100%;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
  font-family: var(--font-lato);
}

.dutiesWrapper {
  margin: 7%;
}

.dutiesGroupWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin: 1%;
}

.eachIconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.eachIconWrapperEye {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.iconImgPatrols {
  position: relative;
  width: 4.6rem;
  object-fit: cover;
}

.iconImgHatchery {
  position: relative;
  width: 4.8rem;
  object-fit: cover;
}

.iconImgReforestation {
  position: relative;
  width: 4.6rem;

  object-fit: cover;
}

/* SECCION  DOS*/

.sectionTwoWrapper {
  font-size: 1.2rem;
  font-family: var(--font-lato);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.eachSegmentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0.7% 0%;
}
.titleStyle {
  display: flex;
  font-size: 1.5rem;
  letter-spacing: -0.02em;
  font-weight: 800;
  color: var(--esmeralda);
  line-height: 150%;
  margin: 3px 0px;
  flex-direction: column;
  align-items: flex-start;
}
.paragraphStyle {
  color: var(--color-black);
  text-align: justify;
}

/* SECTION THREE */
.sectionThreeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5%;
}

.secondTitle {
  position: relative;
  font-size: 1.5rem;
  display: inline-block;
  font-family: var(--font-lato);
  color: var(--esmeralda);
  width: 100%;
  text-align: center;
}

.imgSchedule {
  width: 51.93rem;
  height: 100%;
  object-fit: cover;
}
