@media (max-width: 450px) {
  .ourMissionWrapper,
  .ourVisionWrapper {
    display: flex;
    flex-direction: column;
  }
  .titlemissionabouus,
  .titlevisionaboutus {
    width: 100%;
  }
  .textmissionaboutus,
  .textvisionaboutus {
    width: 100%;
  }

  .factsWrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0%;

  }
  .sectionthree{
    margin-bottom: 30%;

  }

  .textsectionthree{
    width: 70%;

  }

  .titlesectionthree1{
    width: 70%;

  }

  .titlesectionthree2{
    width: 70%;

  }


}

@media (min-width: 451px) {
  .titlemissionabouus,
  .titlevisionaboutus {
    width: 30%;
  }
  .textmissionaboutus,
  .textvisionaboutus {
    width: 70%;
  }
  .factsWrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }


  .textsectionthree{
    width: 50%;

  }

  .titlesectionthree1{
    width: 50%;

  }

  .titlesectionthree2{
    width: 50%;

  }
  .factsWrapper{

    
  }



}

/* ESTILOS PARA TODAS LAS SECCIONES */
.aboutUs {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  text-align: left;
  color: var(--color-black);
  font-family: var(--font-lato);
}

/* ESTILO PARA LA SECCION UNO */
.sectionone {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: justify;
  width: 100%;
  font-size: 1.2rem;
  margin: 60px 0px;
}

.ourMissionWrapper,
.ourVisionWrapper {
  display: flex;
}

.ourVisionWrapper {
  width: 70%;
  align-items: center;
  margin: 14px 0px;
}

.ourMissionWrapper {
  width: 70%;
  align-items: center;
}

.titlemissionabouus,
.titlevisionaboutus {
  font-size: var(--font-size-21xl);
  font-weight: 800;
  color: var(--esmeralda);
  text-align: left;
}
.titlevisionaboutus {
  top: 21.14rem;
}

/* ESTILOS PARA SECCION DOS */
.sectiontwo {
  margin: 40px 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  align-items: center;
  justify-content: space-evenly;
}
.factsWrapper {
  width: 68%;
}

.textfacts {
  text-align: justify;
  height: 100%;
  font-size: var(--font-size-13xl);
}
.figureoneaboutus {
  padding: 18% 10%;
  border-radius: 50%;
  background-color: var(--color-lightblue);
  width: 7.14rem;
  height: 7.29rem;
}
.dataone {
  font-size: 2.7rem;
  text-align: left;
  width: 9.86rem;
  height: 6.14rem;
}

.textdataone {
  text-align: center;
}
.factone {
  width: 7.2rem;
  height: 12.64rem;
  text-align: center;
}

.datatwo,
.textdatatwo {
  display: inline-block;
}

.datatwo {
  font-size: 2.7rem;
  text-align: left;
  width: 5.39rem;
  height: 4.14rem;
}

.facttwo {
  width: 7.2rem;
  height: 12.64rem;
  text-align: center;
}

.textdatathree {
  display: inline-block;
}
.datathree {
  font-size: 2.7rem;
  text-align: left;
  width: 9.86rem;
  height: 6.14rem;
}
.factthree {
  width: 7.2rem;
  height: 12.64rem;
  text-align: center;
}

/* ESTILOS SECCION TRES */
.sectionthree {
  display: flex;
  width: 100%;
  margin: 30px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-lato);
}
.subtitlesectionthree {
  display: inline-block;
  width: 55.93rem;
}

.textsectionthree {
  text-align: justify;
  display: inline-block;
  font-family: var(--font-lato);
}

.titlesectionthree1 {
  font-size: var(--font-size-21xl);
  display: inline-block;
  color: var(--esmeralda);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.titlesectionthree2 {
  display: inline-block;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}



