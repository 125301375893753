
.footer {
    background-color: #0C6267;
    color: #ffffff;
    padding: 30px;
    position:relative;
    left: 0;
    bottom: 0;
    width: 100%;
    font-family: Lato;
    display: none;
}
.icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin-bottom: 17px;
    width: 100%;
    justify-content: space-evenly;
}

.text{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    color:#ffffff;


}

.iconName{

    display: flex;
    flex-direction: column;
    align-items: center;
    
}

@media (max-width: 451px) {
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
       
    }
}

